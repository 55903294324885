/* . is in front of classNames */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; /* font color */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#button {
  background-color: white;
  padding: 10px;
  width: 100px;
  color:red;
  border-radius: 10px;
  border-color: white

}

#button1 {
  background-color: white;
  padding: 10px;
  width: 100px;
  color:red;
  border-radius: 10px;
  border-color: white;
  font-family: 'Times New Roman';


}

#button2 {
  background-color: white;
  padding: 10px;
  width: 100px;
  color:red;
  border-radius: 10px;
  border-color: white;
  font-family: 'Times New Roman';

}

#button3 {
  background-color: white;
  padding: 10px;
  width: 100px;
  color:red;
  border-radius: 10px;
  border-color: white;
  font-family: 'Times New Roman';


}
#button4 {
  background-color: white;
  padding: 10px;
  width: 100px;
  color:red;
  border-radius: 10px;
  border-color: white;
  font-family: 'Times New Roman';


}


#message0{
  color:red;
  font-family: 'Times New Roman';
}

#message1{
  color:red;
  font-family: 'Times New Roman';
}
#message2{
  color:red;
  font-family: 'Times New Roman';
}
#message3{
  color:red;
  font-family: 'Times New Roman';
}
#message4{
  color:red;
  font-family: 'Times New Roman';
}
#message5{
  color:red;
  font-family: 'Times New Roman';
}
#message6{
  color:red;
  font-family: 'Times New Roman';
}
#message7{
  color:red;
  font-family: 'Times New Roman';
}
#message8{
  color:red;
  font-family: 'Times New Roman';
}
#message9{
  color:red;
  font-family: 'Times New Roman';
}
#message49{
  color:red;
  font-family: 'Times New Roman';
}
#message349{
  color:red;
  font-family: 'Times New Roman';
}

#message94{
  color:red;
  font-family: 'Times New Roman';
}

#message88{
  color:red;
  font-family: 'Times New Roman';
}

#message149{
  color:red;
  font-family: 'Times New Roman';
}


#message749{
  color:red;
  font-family: 'Times New Roman';
}
#message849{
  color:red;
  font-family: 'Times New Roman';
}
#message949{
  color:red;
  font-family: 'Times New Roman';
}
#message1049{
  color:red;
  font-family: 'Times New Roman';
}
#message1149{
  color:red;
  font-family: 'Times New Roman';
}
#message1249{
  color:red;
  font-family: 'Times New Roman';
}

#message1349{
  color:red;
  font-family: 'Times New Roman';
}
#message1449{
  color:red;
  font-family: 'Times New Roman';
}
#message1549{
  color:red;
  font-family: 'Times New Roman';
}
#message1649{
  color:red;
  font-family: 'Times New Roman';
}
#message1749{
  color:red;
  font-family: 'Times New Roman';
}
#message1849{
  color:red;
  font-family: 'Times New Roman';
}
#message1949{
  color:red;
  font-family: 'Times New Roman';
}
#message2049{
  color:red;
  font-family: 'Times New Roman';
}
#message2149{
  color:red;
  font-family: 'Times New Roman';
}
#message2249{
  color:red;
  font-family: 'Times New Roman';
}
#message2349{
  color:red;
  font-family: 'Times New Roman';
}
#message2449{
  color:red;
  font-family: 'Times New Roman';
}
#message2549{
  color:red;
  font-family: 'Times New Roman';
}
#message2649{
  color:red;
  font-family: 'Times New Roman';
}
#message2749{
  color:red;
  font-family: 'Times New Roman';
}
#message2949{
  color:red;
  font-family: 'Times New Roman';
}
#message2849{
  color:red;
  font-family: 'Times New Roman';
}
#message3049{
  color:red;
  font-family: 'Times New Roman';
}
#message3149{
  color:red;
  font-family: 'Times New Roman';
}
#message3249{
  color:red;
  font-family: 'Times New Roman';
}
#message1000{
  color:red;
  font-family: 'Times New Roman';
}

#message513{
  color:red;
  font-family: 'Times New Roman';
}
#message613{
  color:red;
  font-family: 'Times New Roman';
}
#message5513{
  color:red;
  font-family: 'Times New Roman';
}
#message55{
  color:red;
  font-family: 'Times New Roman';
}
#message65{
  color:red;
  font-family: 'Times New Roman';
}
#message58{
  color:red;
  font-family: 'Times New Roman';
}
#message59{
  color:red;
  font-family: 'Times New Roman';
}
#message758{
  color:red;
  font-family: 'Times New Roman';
}
#message759{
  color:red;
  font-family: 'Times New Roman';
}
#message68{
  color:red;
  font-family: 'Times New Roman';
}
#message69{
  color:red;
  font-family: 'Times New Roman';
}
#message768{
  color:red;
  font-family: 'Times New Roman';
}
#message769{
  color:red;
  font-family: 'Times New Roman';
}
#message755{
  color:red;
  font-family: 'Times New Roman';
}
#message765{
  color:red;
  font-family: 'Times New Roman';
}

#displaytext{
  font-family: 'Times New Roman';
}

#square {
  width: 50px;
  height: 50px;
  background: red;
}

#table {
  border: 5px white;
  width: 800px;
  height: 200px;
}

th {
  border: 1px white;
  width: 100px;
  height: 100px;
}
  
td {
  text-align: center;
  border: 1px solid white;
  width: 100px;
  height: 50px;
}

#TbFlame{
  color:red;
  width: 100px;
  height: 100px;
}
